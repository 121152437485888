import React from 'react'

import './About.scss'

import eleanor from '../../assets/images/eleanor-glennon.jpeg'

const About = () => {
  return (
    <div className='about'>
        <img className='about-image' src={eleanor} alt='Eleanor Glennon' />
        <div className='about-text-container'>
            <span className='about-label'>About Me</span>
            <span className='about-title'>Hi there! I’m Eleanor Glennon.</span>
            <span className='about-text'>Note: Eleanor is not accepting private practice clients at this time</span>
            <span className='about-text'>I am a Child and Adolescent Psychotherapist specialising in Play Therapy. I am a fully accredited member of IAPTP. I run a private practice in rural Kinnegad, and deliver workshops across the country.</span>
            <span className='about-text'>Play Therapy allows children to express their thoughts and feelings the best way they know how, through play! Attending Play Therapy with a Child and Adolescent Psychotherapist allows a child to learn new ways of communicating and helps them to work through difficult emotions. I work in a child-led manner, as I believe that allowing a safe and congruent relationship between the therapist and child is where the healing really takes place. It is my privilege to help families strengthen their bond and to watch children work through challenging emotions which have been holding them back</span>
            <span className='about-text'>Eleanor is an Associate Lecturer with PCI College and an adult educator with Scoil Mhuire Community School in Clane, teaching subjects including Child Psychology, Special Needs Assisting, Child and Adolescent Psychotherapy, Counselling, amongst others.</span>
        </div>
    </div>
  )
}


export default About;