import React from 'react'

import './FAQ.scss'

const FAQ = () => {
  return (
    <div className='faq'>
        <span className='faq-label'>FAQ</span>
        <span className='faq-title'>Frequently Asked Questions</span>
        <div className='faq-card'>
            <span className='faq-card-question'>How long will my child need to go to play therapy for?</span>
            <span className='faq-card-answer'>This is a very difficult question to answer, as all children respond differently to play therapy and sometimes things move faster or slower than anticipated! It usually takes about 6-8 sessions for children to really start to open up, and for trust and a relationship to develop between the child and therapist, at which point a review would be called for the therapist to discuss the progress the child has made with the child’s parents. At this point, all parties share their observations or concerns, and a plan is developed for going forward. Sometimes this looks like another 6-8 sessions, and often times it can be more.</span>
        </div>
        <div className='faq-card'>
            <span className='faq-card-question'>Will I be in the playroom or will it just my child?</span>
            <span className='faq-card-answer'>This depends on what stage of therapy your child is at. Sometimes children need their parent/caregiver in the room with them for comfort initially, or at certain stages in the therapy process. Additionally, Eleanor will occasionally hold sessions where the child and parent are together to work on the relationship or attachment issues which may have come up during the therapy process! In certain cases, family sessions will be held where Eleanor facilitates the family working together on therapeutic activities and games to build and strengthen the relationship. Other than in these situations, the child will play 1-1 with Eleanor.</span>
        </div>
        <div className='faq-card'>
            <span className='faq-card-question'>How do I explain how play therapy works to my child?</span>
            <span className='faq-card-answer'>It is a wonderful thing to seek help for yourself when you need it, and if children receive this message from their parents in a way that inspires self-confidence and pride, it can make a huge difference in the child’s perception of play therapy.</span>
        </div>
    </div>
  )
}

export default FAQ;
