import React from 'react'
import Button from '../Button/Button';

import './ReferralIssues.scss'
 
const ReferralIssues = () => {
  return (
    <div className='issues'>
        <div className='issues-text'>
            <span className='issues-title'>Typical Referral Issues</span>
            <span className='issues-subtitle'>These are the types of issues that I help kids work to overcome.</span>
        </div>
        <div className='issues-list'>
          <Button text="Elective Mutism" />
          <Button text="Anxiety" />
          <Button text="ADD, ADHD, Autism" />
          <Button text="Bullying" />
          <Button text="Low Self-Esteem" />
          <Button text="Trauma" />
          <Button text="Chronic Illness/Hospitalisation" />
          <Button text="Delayed or Uneven Development" />
          <Button text="Abuse or Neglect" />
          <Button text="Fostering, Adoption and Identity issues" />
          <Button text="Bonding and Attachment issues" />
          <Button text="Family Disruptions such as Illness or Divorce" />
          <Button text="Bereavement or Loss" />
          <Button text="Emotional or Behavioural Issues" />
          <Button text="Communication Difficulties" />
          <Button text="Learning Difficulties" />
          <Button text="Relationship Difficulties" />
        </div>
    </div>
  )
}

export default ReferralIssues;
