import React, { useState } from 'react'
import { Instagram, Linkedin } from 'react-feather';
import Modal from 'react-modal';

import './ContactModal.scss'

Modal.setAppElement( '#root' )

interface ModalProps {
    isOpen: boolean;
    handleClose: () => any;
}

const ContactModal: React.FC<ModalProps> = ({ isOpen, handleClose }) => {
    const [ contents, setContents ] = useState<string>('');
    const [ title, setTitle ] = useState<string>('');

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setTitle(e.target.value);
    };
    
    const handleContents = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setContents(e.target.value );
    };

    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="contact-modal-overlay"
            className="contact-modal"
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleClose}
            shouldFocusAfterRender={false}
            preventScroll={true}
        >    
            <div className='contact-modal-header-row'>
                <span className="contact-modal-title">Get in Contact!</span>
                <div className='contact-modal-social-links'>
                    <a className='footer-social-link' href="https://www.instagram.com/kinnegadplaytherapy/" target='_blank' rel="noreferrer"> 
                        <Instagram />
                    </a>
                    <a className='footer-social-link' href="https://www.linkedin.com/eleanor-glennon?_l=en_US" target='_blank' rel="noreferrer"> 
                        <Linkedin />
                    </a>
                </div>
            </div>
            <div className='contact-modal-input-form'>
                <input
                    type="email"
                    value={title}
                    onChange={handleTitle}
                    maxLength={50}
                    placeholder="Email Subject..."
                />
                <textarea
                    value={contents}
                    onChange={handleContents}
                    rows={8}
                    maxLength={2000}
                    placeholder="Email Body..."
                />
                <a
                    href={`mailto:kinnegadplaytherapy@gmail.com?subject=${title}&body=${contents.replace(/\n/g, '%0D%0A')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Send Email
                </a>
            </div>
        </Modal>
    )
}

export default ContactModal;