import React from 'react'

import './PlayTherapy.scss'

const PlayTherapy = () => {
  return (
    <div className='play-therapy'>
        <span className='play-therapy-label'>Understanding Play Therapy</span>
        <div className='play-therapy-section'>
            <span className='play-therapy-title'>Who can Play Therapy help?</span>
            <span className='play-therapy-text'>“Research has found Play Therapy to be beneficial for every child and can be used as a preventative measure. It helps children make sense of their world, reaching their full potential while developing resilience and emotional intelligence.</span>
            <span className='play-therapy-text'>It is also a psychotherapeutic intervention when offered by appropriately qualified practitioners. Play therapy practitioners who are not psychotherapists are not intended to provide treatment for children with complex issues including those linked to trauma, attachment disruptions, or mental health concerns. In such cases the referral should be made to a psychotherapist with a specialisation in play therapy who can provide a developmentally appropriate intervention to address difficulties and distress.” (IAPTP, 2021).</span>
        </div>
        <div className='play-therapy-section'>
          <span className='play-therapy-title'>Methods of Play Therapy</span>
          <span className='play-therapy-text'>Play Therapy with a trained and qualified Psychotherapist offers children a space where they can make sense of their feelings through play, which is the most developmentally appropriate means of therapy for children. Through play, children can express themselves and address social and emotional difficulties they may be experiencing more effectively than through just simply talking. In play therapy, toys are like the child’s words and play is the child’s language (Landreth, 2002).</span>
          <span className='play-therapy-text'>Eleanor is a Humanistic and Integrative Psychotherapist, and takes the client’s lead in the process. This allows children to find and utilise their natural ability to heal. Eleanor uses a child-centred approach, and allows the child’s immediate needs and their history to inform her decision-making for each session. The methods utilised at Kinnegad Play Therapy are both non-directive and directive which means that the child leads the play, with Eleanor introducing suitable therapeutic activities and interventions where appropriate. What does this look like? Lots of playing, sand, art, puppets, slime, board-games and whatever the session calls for!</span>
        </div>
    </div>
  )
}


export default PlayTherapy;