import { useEffect, useState } from "react";
import About from "./components/About/About";
import ContactModal from "./components/ContactModal/ContactModal";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import PlayTherapy from "./components/PlayTherapy/PlayTherapy";
import ReferralIssues from "./components/ReferralIssues/ReferralIssues";
import Events from "./components/Events/Events";
import WebsiteHeader from "./components/WebsiteHeader/WebsiteHeader";
import './styles/main.scss'


interface KPTNotionContent {
  aboutDesc: string
  aboutHeader: string
  aboutImage: string
  contactLinks: {type: string, value: string}[]
  contactTitle: string
  frequentlyAskedQuestions: {question: string, answer: string}[]
  playTherapyExplainedDescOne: string
  playTherapyExplainedDescTwo: string
  playTherapyExplainedTitleOne: string
  playTherapyExplainedTitleTwo: string
  referralIssues: { issue: string }[]
  welcomeHeader: string
  welcomeImage: string
  welcomeSubheader: string
  eventsDesc: string
  eventsTitle: string
}

const KPTWebsite = () => {
  const [ contactModalOpen, setContactModalOpen ] = useState<boolean>(false);


  const handleModalState: ( newState: 'open' | 'closed' ) => () => void = (newState) => {
    if (newState === 'closed') {
      return () => setContactModalOpen(false)
    } else {
      return () => setContactModalOpen(true)
    }
  }

  return (
    <div id="app-container">
      <Navbar openContactModal={handleModalState('open')} />
      <WebsiteHeader />
      <About />
      <ReferralIssues />
      <PlayTherapy />
      <Events />
      <FAQ />
      <Footer openContactModal={handleModalState('open')} />
      <ContactModal isOpen={contactModalOpen} handleClose={handleModalState('closed')} />
    </div>
  );
}

export default KPTWebsite;
