import React from 'react'
import Button from '../Button/Button';

import './Navbar.scss'

interface NavbarProps {
  openContactModal: () => any;
}

const Navbar: React.FC<NavbarProps> = ({ openContactModal }) => {
  return (
    <div className='nav'>
        <span className='nav-title'>Kinnegad Play Therapy</span>
        <div className='nav-links-container'>
          <Button text='Get in Contact!' onClick={openContactModal} />
        </div>
    </div>
  )
}

export default Navbar;
