import React from 'react'

import './WebsiteHeader.scss'

import welcomeImage from '../../assets/images/welcome-image.png'

const WebsiteHeader = () => {
  return (
    <div className='site-header'>
        <div className='site-header-text-container'>
            <span className='site-header-title'>Birds fly, fish swim, and children play.</span>
            <span className='site-header-subtitle'>- Garry L. Landreth</span>
        </div>
        <div className='site-header-image-container'>
          <img src={welcomeImage} className='site-header-image' alt='Children playing with blocks.' />
        </div>
    </div>
  )
}

export default WebsiteHeader;
