import React from 'react'

import './Events.scss'


const Events = () => {
  return (
    <div className='events'>
        <span className='events-label'>Events</span>
        <div className='events-section'>
          <span className='events-title'>Workshops / Talks</span>
          <span className='events-text'>Eleanor delivers workshops and talks which are bespoke and tailored to your training needs. Talks on anxiety, autism, behaviours that challenge, sensory play, parenting and more have been successfully created and delivered through Westmeath, Offaly, Kildare, Laois and Monaghan Libraries, and within multiple schools across Ireland.</span>
          <span className='events-text'>Larger scale talks have been delivered through the Youth Libraries Group on Utilising Sensory Rooms and Understanding Sensory Systems, and how to make libraries inclusive for children who have sensory processing difficulties or additional needs.</span>
          <span className='events-text'>Get in touch to discuss your training needs!</span>
        </div>
    </div>
  )
}


export default Events;