import React from 'react'
import { Instagram, Linkedin, Mail } from 'react-feather';

import './Footer.scss';

interface FooterProps {
  openContactModal: () => void;
}

const Footer: React.FC<FooterProps> = ({ openContactModal }) => {
  return (
    <div className='footer'>
        <div className='footer-social-links'>
          <a className='footer-social-link' href="https://www.instagram.com/kinnegadplaytherapy/" target='_blank' rel="noreferrer"> 
            <Instagram />
          </a>
          <Mail className='footer-social-link' onClick={openContactModal} />
          <a className='footer-social-link' href="https://www.linkedin.com/eleanor-glennon?_l=en_US" target='_blank' rel="noreferrer"> 
            <Linkedin />
          </a>
        </div>
        <div className='footer-ndd-note'>
            <span>Designed and built by Nick Doherty</span>
            <a href='https://ndoherty.xyz' target='_blank' rel="noreferrer">ndoherty.xyz</a>
        </div>
    </div>
  )
}


export default Footer;