import React from 'react'

import './Button.scss'

interface ButtonProps {
  text: string;
  onClick?: () => any;
  width?: string;
}

const Button: React.FC<ButtonProps> = ({text, onClick, width}: ButtonProps) => {
  return (
    <div 
      className={`button ${ !onClick ? 'unclickable' : '' }`} 
      onClick={onClick ? onClick : undefined}
      style={{ width: width ? width : 'fit-content' }}
    >
      {text}
    </div>
  )
}

export default Button;
